import {
  JWTToken,
  NavigationGroup,
  NavigationItem,
  ORGANIZATION_LOGS,
} from 'types';
import { ReactComponent as BookmarkIcon } from 'assets/bookmark.svg';
import { ReactComponent as DashboardIcon } from 'assets/dashboard.svg';
import { ReactComponent as DocumentIcon } from 'assets/document.svg';
import { ReactComponent as ExplorerIcon } from 'assets/explorer.svg';
import { ReactComponent as FAQIcon } from 'assets/faq.svg';
import { ReactComponent as TaskIcon } from 'assets/tasks.svg';
import { ReactComponent as SettingsIcon } from 'assets/settings.svg';
import { ReactComponent as SupportIcon } from 'assets/support.svg';
import { ReactComponent as WorkflowsIcon } from 'assets/workflows.svg';
import { ReactComponent as WorkflowReportIcon } from 'assets/workflow_report.svg';
import { ReactComponent as OrganisationIcon } from 'assets/company.svg';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import { ReactComponent as GroupsIcon } from 'assets/user_group.svg';
import { ReactComponent as WebhooksIcon } from 'assets/webhooks.svg';
import { ReactComponent as PolicyIcon } from 'assets/policyMapping.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as UploadIcon } from 'assets/upload-icon.svg';
import { ReactComponent as AdminIcon } from 'assets/admin.svg';
import { ReactComponent as PivotTableIcon } from 'assets/pivot-table.svg';
import { ReactComponent as OrganisationLogs } from 'assets/log_icon.svg';
import { ReactComponent as OrganisationLogsLocked } from 'assets/log_locked.svg';
import DocumentBundleIcon from 'assets/document_bundle';
import config from 'config';

export type HasAccessHandler = (feature: string) => boolean;

const getNavigationForUser = (
  jwtToken: JWTToken,
  hasAccess: HasAccessHandler,
  email: string,
) => {
  const isWaymarkStaff =
    jwtToken.payload.is_superuser || jwtToken.payload.is_staff;
  const bottomNavGroup: NavigationGroup = [
    {
      title: 'FAQ',
      icon: FAQIcon,
      link: 'https://docs.waymark.tech',
    },
    {
      title: 'Support',
      icon: SupportIcon,
      anchor: 'support',
      link: `mailto:${config.supportEmail}`,
    },
  ];

  const baseTopNavGroups: Array<NavigationGroup> = [
    [
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        link: '/dashboard',
      },
    ],
  ];

  if (isWaymarkStaff) {
    baseTopNavGroups[0].push({
      title: 'Upload',
      icon: UploadIcon,
      link: '/upload',
    });
  }

  if (isWaymarkStaff) {
    bottomNavGroup.splice(0, 0, {
      title: 'Staff Only',
      icon: AdminIcon,
      nestedNav: {
        topNavGroups: [
          [
            {
              title: 'Organisations',
              icon: OrganisationIcon,
              link: '/organisations/',
            },
            {
              title: 'Groups',
              icon: GroupsIcon,
              link: '/groups/',
            },
            {
              title: 'Users',
              icon: UserIcon,
              link: '/users/',
            },
            {
              title: 'Logs',
              icon: OrganisationLogs,
              link: '/organisation-logs/',
            },
            {
              title: 'Pivot table',
              icon: PivotTableIcon,
              link: '/pivot-table/',
            },
            {
              title: 'Report templates',
              icon: PivotTableIcon,
              link: '/report-templates/',
            },
            {
              title: 'Policy mapping',
              icon: PolicyIcon,
              link: '/policy-mapping/',
            },
            {
              title: 'Document Bundles',
              icon: DocumentBundleIcon,
              link: '/document-bundles/',
            },
            {
              title: 'Webhooks',
              icon: WebhooksIcon,
              link: '/webhooks/',
            },
          ],
        ],
      },
    });
  }

  if (!isWaymarkStaff && jwtToken.payload.is_org_creator) {
    baseTopNavGroups.push([
      {
        title: 'Organisations',
        icon: OrganisationIcon,
        link: '/organisations/',
      },
    ]);
  }

  baseTopNavGroups.push([
    {
      title: 'Document library',
      icon: DocumentIcon,
      link: '/folders',
    },
    {
      title: 'Search',
      icon: ExplorerIcon,
      link: '/search',
    },
    {
      title: 'Saved searches',
      icon: BookmarkIcon,
      link: '/saved-searches',
    },
  ]);

  let middleNavGroup: Array<NavigationItem> = [];
  if (jwtToken.payload.are_workflows_allowed || isWaymarkStaff) {
    middleNavGroup = [
      {
        title: 'Workflows',
        icon: WorkflowsIcon,
        link: '/workflows',
      },
      {
        title: 'Workflow Report',
        icon: WorkflowReportIcon,
        link: '/workflow-report',
      },
      {
        title: 'Tasks',
        icon: TaskIcon,
        link: '/tasks',
      },
    ];
  }

  if (!isWaymarkStaff && jwtToken.payload.are_workflows_allowed) {
    middleNavGroup.splice(2, 0, {
      title: 'Report Templates',
      icon: PivotTableIcon,
      link: '/report-templates/',
    });
  }

  middleNavGroup.splice(3, 0, {
    title: 'Regulatory Calendar',
    icon: CalendarIcon,
    link: '/regulatory-calendar/',
  });

  baseTopNavGroups.push(middleNavGroup);

  if (jwtToken.payload.is_org_admin) {
    const nestedTopNavGroups = [];

    if (!jwtToken.payload.is_sso_only) {
      if (hasAccess(ORGANIZATION_LOGS)) {
        nestedTopNavGroups.push({
          title: 'Logs',
          icon: OrganisationLogs,
          link: '/organisation-logs/',
        });
      } else {
        nestedTopNavGroups.push({
          title: 'Logs',
          icon: OrganisationLogsLocked,
        });
      }
    }

    if (
      !jwtToken.payload.is_sso_only &&
      !jwtToken.payload.file_uploads_access_only
    ) {
      nestedTopNavGroups.push({
        title: 'Webhooks',
        icon: WebhooksIcon,
        link: '/webhooks/',
      });
    }

    bottomNavGroup.unshift({
      title: 'Settings',
      icon: SettingsIcon,
      nestedNav: {
        topNavGroups: [
          [
            {
              title: 'Organisation',
              icon: OrganisationIcon,
              link: '/organisation/',
            },
            {
              title: 'Organisation Groups',
              icon: GroupsIcon,
              link: '/organisation/groups/',
            },
            {
              title: 'Organisation Users',
              icon: UserIcon,
              link: '/organisation/users/',
            },
            ...nestedTopNavGroups,
          ],
        ],
        bottomNavGroups: [[]],
      },
    });
  }

  if (jwtToken.payload.file_uploads_access_only && !isWaymarkStaff) {
    return {
      topNavGroups: [
        [
          {
            title: 'Upload',
            icon: UploadIcon,
            link: '/upload',
          },
        ],
      ],
      bottomNavGroups: [bottomNavGroup],
    };
  }

  return {
    topNavGroups: baseTopNavGroups,
    bottomNavGroups: [bottomNavGroup],
  };
};

export default getNavigationForUser;
